import { render, staticRenderFns } from "./CMSDiseasesHealthInformation.vue?vue&type=template&id=ad9b211a&scoped=true"
import script from "./CMSDiseasesHealthInformation.vue?vue&type=script&lang=js"
export * from "./CMSDiseasesHealthInformation.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ad9b211a",
  null
  
)

export default component.exports